.edutech-img-size {
  width: 180px;
  height: 131px;
}

.bold-400 {
  font-weight: 400;
}

.bold-500 {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.bold-700 {
  font-weight: 700;
}

.bold-800 {
  font-weight: 800;
}

.edutech-card-course-text {
  font-size: 22px;
  font-weight: 700;
  color: var(--secondary-color);
  text-align: center;
}

.Explore .explore-img-set img {
  width: 6rem;
  border-radius: 15px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.edutech-courses-cards {
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
  position: initial;
}

.edutech-button-color {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  border-radius: 15px;
}

.card {
  border-radius: 15px;
}

.edutech-dashboard-welcome-text {
  color: var(--secondary-color);
}

.edutech-dashboard-first-card,
.board-card,
.quote-card,
.goal-card {
  background-color: var(--primary-color);
}

.edutech-dashboard-first-card .bitcoin {
  margin-bottom: 11px;
  margin-top: 2px;
}

.edutech-dashboard-first-card .bitcoin svg {
  color: #787676;
  margin-left: 0.5rem;
  cursor: pointer;
}

.edutech-dashboard-first-card .card-body .header-top-section,
.edutech-dashboard-second-card .card-body .header-top-section {
  display: flex;
  position: relative;
  align-items: center;
}

.header-bottom-section .hour-section {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  margin: auto;
}

.header-bottom-section .hour-section .progress {
  line-height: 0;
  width: 100%;
  height: 7px;
}

.header-bottom-section .hour-section .hour-b-side {
  display: block;
  width: 100%;
  max-width: 35px;
}

.edutech-dashboard-first-card .card-body .header-top-section .left-side svg,
.edutech-dashboard-second-card .card-body .header-top-section .left-side svg {
  color: #c4c4c4;
}

.edutech-dashboard-second-card {
  background-color: var(--secondary-color);
}

.text-green {
  color: #00386e;
}

.edutech-dashboard-icon {
  line-height: 0;
  margin-right: 15px;
  color: var(--primary-color);
  border-radius: 10px;
  padding: 10px;
}

.cursor {
  cursor: pointer;
}

.edutech-dashboard-first-card .progress-bar {
  background-color: var(--secondary-color);
}

.edutech-dashboard-second-card .progress-bar {
  background-color: var(--primary-color);
}

.edutech-dashboard-first-card .progress {
  border-radius: 3px;
}

.edutech-dashboard-second-card .progress {
  border-radius: 2px;
}

.table-adjust .table-striped tbody tr:nth-of-type(odd) {
  background: #e8f0f8;
  border: 0;
}

.edutech-hr-colors {
  border-top: 1px solid #d9d5d5;
}

.table-adjust .edutech-hr-colors,
.header-bottom-section hr {
  border-top: 1px solid #bfbfbf;
}

.table-adjust .table td,
.table th {
  border: 0;
  vertical-align: middle;
  font-weight: 500;
}

.heart-icon {
  color: #c7c7c7;
  margin-top: 2px;
}

.border-right {
  border-right: 1px solid #c7c7c7;
}

.f-12 {
  font-size: 12px;
}

.h-30 {
  height: 30px;
}

.w-30 {
  width: 30px;
}

.w-85 {
  width: 85%;
}

.f-16 {
  font-size: 16px;
}

.f-23 {
  font-size: 23px;
}

.mb-32 {
  margin-bottom: 32px;
}

.card-text-margin {
  margin-top: 30px;
}

.dots-size {
  width: 30px;
  height: 30px;
}

.video-card-height {
  padding: 0;
}

.video-height {
  max-height: 300px;
  height: 100%;
  border-radius: 15px;
}

.video-card-height .video-height {
  max-height: 208px;
  height: 100%;
  border-radius: 15px;
}

.board-card .board-card-icon,
.goal-card .goal-card-icon,
.quote-card .quote-card-icon {
  background-color: var(--secondary-color);
  color: #fff;
  border-radius: 50%;
  text-align: center;
  width: 60px;
  height: 60px;
  padding: 14px;
}

.board-card button,
.goal-card button,
.quote-card button {
  background-color: #fff;
  color: #000;
  border-radius: 2rem;
  text-align: center;
  padding: 7px 18px;
  text-transform: uppercase;
  border: none;
  margin: 0rem 0 1rem 0;
  font-weight: 500;
}

.board-card .board-card-inner,
.goal-card .goal-card-inner,
.quote-card .quote-card-inner {
  height: 18.55rem;
  overflow: auto;
}

.board-card .board-card-inner .card-body,
.goal-card .goal-card-inner .card-body,
.quote-card .quote-card-inner .card-body {
  height: 18.55rem;
  overflow: auto;
}

.board-card .board-card-inner .card-body::-webkit-scrollbar,
.goal-card .goal-card-inner .card-body::-webkit-scrollbar,
.quote-card .quote-card-inner .card-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.board-card .board-card-inner .card-body::-webkit-scrollbar-track,
.goal-card .goal-card-inner .card-body::-webkit-scrollbar-track,
.quote-card .quote-card-inner .card-body::-webkit-scrollbar-track {
  background: transparent;
}

.board-card .board-card-inner .card-body::-webkit-scrollbar-thumb,
.goal-card .goal-card-inner .card-body::-webkit-scrollbar-thumb,
.quote-card .quote-card-inner .card-body::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 25px;
}

.board-card .board-card-inner .card-body::-webkit-scrollbar-thumb:hover,
.goal-card .goal-card-inner .card-body::-webkit-scrollbar-thumb:hover,
.quote-card .quote-card-inner .card-body::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color);
}

.board-card .board-card-inner h3,
.goal-card .goal-card-inner h3,
.quote-card .quote-card-inner h3 {
  color: var(--primary-color);
  font-weight: 700;
}

.board-card .board-card-inner h4,
.quote-card .quote-card-inner h4 {
  color: var(--grey-color);
  font-weight: 600;
  font-size: 20px;
}

.quote-card .quote-card-inner .card-body {
  position: relative;
}

.quote-card .quote-card-inner .card-body .center-things {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 48%;
  margin-right: -31%;
  transform: translate(-50%, -50%);
}

.board-card .board-card-inner p {
  color: #000;
  font-size: 12px;
  font-weight: 500;
}

.edutech-about-speaker-icons {
  display: flex;
  text-align: center;
  justify-content: space-around;
  font-size: 20px;
  color: var(--primary-color);
}

.card .f-20 {
  font-size: 15pt;
  font-weight: 600;
}

.edutech-speaker-text-detail {
  color: #bdbdbd;
  font-size: 14px;
}

.user-img .edutech-speaker-img {
  height: 60px;
  object-fit: cover;
  width: 60px;
}

.edutech-actions-bookmark-icon {
  font-size: 30px;
  color: var(--primary-color);
  cursor: pointer;
}

.edutech-action-button {
  padding: 15px;
  border-radius: 15px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 10px !important;
  background-color: var(--primary-color) !important;
}

.edutech-bookmark {
  font-size: 18px;
  font-weight: 400;
  margin-left: 1rem;
}

.edutech-complte-course-text {
  font-size: x-large;
  color: var(--primary-color);
  margin-left: 15px;
}

.edutech-complete-congratulate {
  color: var(--primary-color);
}

.edutech-complete-check-icon {
  font-size: 25px;
  color: var(--primary-color);
  padding-top: 3px;
}

.edutech-complete-card-color {
  background-color: #f5f5f5;
}

.edutech-btn-secondary {
  background: #f6f6f6 !important;
  color: var(--primary-color);
  margin-top: 20px;
  font-weight: 500;
  border: none !important;
  border-radius: 10px;
}

.edutech-btn-secondary:hover {
  background: #f6f6f6 !important;
  color: var(--primary-color);
  margin-top: 20px;
  font-weight: 500;
  border: none !important;
}

.edutech-btn-secondary:focus {
  background: #f6f6f6 !important;
  color: var(--Primary-color);
  margin-top: 20px;
  font-weight: 500;
  border: none !important;
}

.edutech-complete-course-card-img-border {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.edutech-certificate-height {
  height: 348px;
}

.edutech-video-span {
  position: absolute;
  top: 30%;
}

.edutech-video-span-determine-text {
  background: var(--primary-color);
  padding: 10px;
  color: white;
  margin-right: 45px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.edutech-video-span-profit-text {
  background: var(--primary-color);
  padding: 10px 45px 10px 45px;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.f-13 {
  font-size: 13px;
}

.f-17 {
  font-size: 17px;
}

.edutech-back-arrow {
  color: black;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

/* Teacher section */
.edutech-teacher-curriculum-uper-tab {
  margin-left: 15rem;
  bottom: 0;
  position: absolute;
}

.edutech-teacher-curriculum-tab-style {
  background: #e5e5e5;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.edutech-teacher-curriculum-parent-tab
  .edutech-teacher-curriculum-uper-tab
  .nav-item.active {
  color: #ffffff;
  background-color: #00d0f1;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 15px;
}

.edutech-teacher-curriculum-parent-tab
  .edutech-teacher-curriculum-uper-tab
  .nav-item {
  padding: 15px 40px 15px 40px;
  background-color: #e5e5e5;
  border-radius: 10px;
}

.edutech-curriculum-gradient-color .nav-pills .nav-link.active {
  color: black;
  font-weight: bolder;
  background: linear-gradient(90deg, #00386e94 0%, rgba(40, 160, 164, 0) 100%);
}

.edutech-curriculum-col {
  background: white;
  border-radius: 15px;
  /* max-height: 223px; */
}

.edutech-curriculum-font {
  font-size: 18px;
  font-weight: 400;
}

.f-16 {
  font-size: 16px;
}

.Uploadfield {
  border: 4px dashed #d7d5d5;
  height: 85px;
  border-radius: 15px;
}

.edutech-curriculum .Uploadtext {
  width: 100%;
  height: 46%;
  text-align: center;
  line-height: 45px;

  font-family: Arial;
}

.edutech-curriculum .Uploadtext2 {
  text-align: center;
}

.edutech-curriculum .Uploadinput {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 90%;
  height: 30%;
  outline: none;
  opacity: 0;
}

.ckeditor-modification
  .ck.ck-editor__main
  > .ck-editor__editable:not(.ck-focused),
.ckeditor-modification
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  height: 100%;
  min-height: 150px;
  max-height: 150px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: none !important;
  border-color: var(--ck-color-base-border) !important;
}

.edutech-max-height {
  max-height: 230px;
}

.edutech-curriculum-action-button {
  margin-top: 20px;
  padding: 15px 5rem 15px 5rem;
  background-color: var(--primary-color) !important;
  border: var(--primary-color) !important;
}

.edutech-curriculum-action-button:active {
  color: #fff !important;
}

.edutech-pull-right {
  float: right;
  border-radius: 15px;
}

.edutech-curriculum-cancel-button {
  background: #e5e5e5 !important;
  border: #e5e5e5 !important;
  color: black;
  margin-top: 20px;
  padding: 15px 6rem 15px 6rem;
  margin-right: 10px;
}

.edutech-curriculum-cancel-button:hover {
  background: #e5e5e5 !important;
  border: #e5e5e5 !important;
  color: black;
}

.edutech-curriculum-checkbox {
  width: 20px;
  height: 20px;
}

.edutech-curriculum-text {
  margin-left: 25px;
  margin-top: 5px;
  border: none;
}

.edutech-curriculum-text-field {
  width: 100%;
  /* height: 61%; */
  padding: 20px;
  border-radius: 15px;
}

.edutech-curriculum-checkbox-field {
  width: 100%;
  height: 30%;
  padding: 15px;
  background: white;
  margin-top: 20px;
  border-radius: 15px;
}

.edutech-bordercolor {
  border: 1px solid var(--primary-color) !important;
}

.edutech-bordercolor-check {
  color: white;
  position: absolute;
  right: 0;
  background: #00386e;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 50%;
  font-size: 20px;
  margin-right: 25px;
  top: 0;
}

.edutech-card-inner-text {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
}

.edutech-card-inner-coach-text {
  font-size: 16px;
  font-weight: 500;
}

.edutech-coaching-name-text {
  padding: 30px !important;
  border-radius: 15px;
  background: #f7f7f7;
}
.new-staff-parent.edutech-coaching-name-text {
  padding: 25px !important;
  border-radius: 15px;
  background: #f7f7f7;
}

.edutech-coaching-checkbox {
  height: 20px;
  width: 20px;
}

.edutech-second-label {
  font-weight: 500;
}

.edutech-coaching-card-color {
  background: #00386e;
  color: white;
}

.edutech-card-tittle-color {
  color: white;
}

.edutech-member-list-span {
  margin-left: 15px;
  padding: 10px;
  background: #f7f8f9;
  border-radius: 15px;
}

.edutech-member-list-main-div {
  margin-top: 5rem;
}

.edutech-group-date-picker {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.edutech-date-padding-text {
  padding-top: 25px;
  padding-bottom: 25px;
}

.edutech-date-padding-field {
  padding-top: 35px !important;
  padding-bottom: 25px !important;
}

.edutech-bolletin-shortlist {
  background: #92e3a9;
  border: #92e3a9;
  border-radius: 15px;
  color: white;
  font-size: 13px;
  padding: 3px 25px 4px 25px;
}

.edutech-bolletin-ignore {
  background: #ff6056;
  border: #ff6056;
  border-radius: 15px;
  color: white;
  font-size: 13px;
  padding: 3px 25px 4px 25px;
}

.edutech-bolletin-edit {
  background: var(--primary-color);
  border: var(--primary-color);
  border-radius: 15px;
  color: white;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
}

.edutech-edit-svg {
  height: 20px;
  width: 20px;
}

.w-5 {
  width: 5%;
}

.w-60 {
  width: 60%;
}

.edutech-checkbox-size {
  height: 17px;
  width: 17px;
}

.edutech-listing-icon {
  width: 25px;
  height: 25px;
}

.edutech-card-text-background {
  background: #f6f6f6;
  /* padding: 12px; */
  padding: 25px 12px 17px 12px;
  color: #718797;
}

.edutech-bulletin-button {
  font-size: 13px;
  background: #f6f6f6 !important;
  border: #f6f6f6 !important;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
}

.edutech-bulletin-button:hover {
  font-size: 13px;
  background: #f6f6f6 !important;
  border: #f6f6f6 !important;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
}

.edutech-bulletin-button:focus {
  font-size: 13px;
  background: #f6f6f6 !important;
  border: #f6f6f6 !important;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
}

.edutech-pull-right-bolletin {
  float: right;
}

.pl-15 {
  padding-left: 75px;
}

.edutech-bolletin-field-color {
  background: #f6f6f6;
  border: none;
  border-radius: 10px;
}

.modal-content {
  border-radius: 15px;
}

.edutech-field-style-proposal {
  background: #f0f0f0;
  border-radius: 10px;
}

.edutech-modal-overflow {
  overflow-x: auto !important;
  min-height: 620px !important;
}

.edutech-button-rounded {
  border-radius: 15px;
}

.edutechaddmodal-overflow {
  overflow-x: auto;
  height: 730px;
}

.edutech-info-send-svg {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 46px;
  margin-right: 30px;
  color: blue;
  font-size: 20px;
}

.edutech-send-field {
  position: relative;
}

.edutechaddmodal .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  margin-left: 0;
}

.edutech-modal-overflow .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  margin-left: 0;
}

.edutech-field-height {
  height: 50px !important;
}

.edutech-plus-field {
  height: 59px !important;

  border-radius: 15px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.sidebar-menu .menu-arrow {
  transition: transform 0.15s;
  position: absolute;
  right: 0px !important;
  display: inline-block;
  font-family: "FontAwesome";
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}

.dropdown-normal-edutech {
  background: #00386e !important;
  color: white !important;
}

/* admindashboard */
.box {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}

.box-header {
  color: #191d33;
  display: block;
  padding: 1.5rem;
  position: relative;
  border-bottom: 1px solid #f0f3f6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.box-header .box-title {
  display: inline-block;
  margin: 0;
  font-weight: 400;
}

.box-title {
  z-index: auto;
}

.fw-500 {
  font-weight: 500 !important;
}

.box-controls {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.pull-up {
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.box-body {
  padding: 1.5rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-radius: 5px;
}

.me-10 {
  margin-right: 10px !important;
}

.h-50 {
  height: 50px !important;
}

.w-50 {
  width: 50px !important;
}

.me-15 {
  margin-right: 15px !important;
}

.l-h-55 {
  line-height: 3.9285714286rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.fs-24 {
  font-size: 1.8461538462rem !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fs-16 {
  font-size: 1.2307692308rem !important;
}

.text-dark {
  color: #191d33 !important;
}

.text-fade {
  color: #a1a4b5 !important;
}

.btn-warning-light {
  background-color: #fef5d4;
  border-color: #fef5d4;
  color: #fec801 !important;
}

.btn-warning-light:hover {
  background-color: #f0bd01 !important;
  border-color: #f0bd01 !important;
  color: #ffffff !important;
}

.btn-danger-light {
  background-color: #fcecf1;
  border-color: #fcecf1;
  color: #f2426d !important;
}

.btn-danger-light:hover {
  background-color: #da224f !important;
  border-color: #da224f !important;
  color: #ffffff !important;
}

.btn-success-light {
  background-color: #d7f3ef !important;
  border-color: #d7f3ef !important;
  color: #58baab !important;
}

.btn-success-light:hover {
  background-color: #3ea898 !important;
  border-color: #3ea898 !important;
  color: #ffffff !important;
}

.btn-primary-light:hover {
  background-color: #0196e7 !important;
  border-color: #0196e7 !important;
  color: #ffffff !important;
}

.btn-primary-light {
  background-color: #e2f3fc !important;
  border-color: #e2f3fc !important;
  color: #019ff8 !important;
}

.edutech .edutech-hover-success:hover {
  color: #58baab !important;
}

.edutech .edutech-hover-success:focus {
  color: #58baab !important;
}

.edutech2 .edutech-hover-primary:hover {
  color: #019ff8 !important;
}

.edutech2 .edutech-hover-primary:focus {
  color: #019ff8 !important;
}

.edutech .edutech-hover-danger:hover,
.edutech .edutech-hover-danger:focus {
  color: #f2426d !important;
}

.edutech .edutech-hover-warning:hover,
.edutech .edutech-hover-warning:focus {
  color: #fec801 !important;
}

.edutech-bg-primary {
  background-color: #019ff8 !important;
  color: #ffffff;
}

.edutech-bg-danger {
  background-color: #f2426d !important;
  color: #ffffff;
}

.edutech-bg-success {
  background-color: #58baab !important;
  color: #ffffff;
}

a.btn {
  text-decoration: none !important;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  transition: 0.3s ease-out;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.dropdown-menu {
  color: #191d33;
  border-radius: 5px;
  font-size: 13px;
  border-color: #f0f3f6;
  padding: 0;
  -webkit-box-shadow: 0 0 4px rgb(0 0 0 / 6%);
  box-shadow: 0 0 4px rgb(0 0 0 / 6%);
  z-index: 992;
}

.dropdown-item {
  color: #7f839a;
  font-weight: 400;
  padding: 10px 15px;
  width: auto;
  margin: 5px;
  border-radius: 5px;
  -webkit-transition: 0.15s linear;
  transition: 0.15s linear;
}

.dropdown-item:active {
  background-color: #e9ecef !important;
  color: #16181b;
}

.flexbox {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.pull-up:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
  -moz-transform: translateY(-4px) scale(1.02);
  -ms-transform: translateY(-4px) scale(1.02);
  -o-transform: translateY(-4px) scale(1.02);
  transform: translateY(-4px) scale(1.02);
  -webkit-box-shadow: 0 14px 24px rgb(0 0 0 / 20%);
  box-shadow: 0 14px 24px rgb(0 0 0 / 20%);
  z-index: 999;
}

.theme-primary .btn-warning-light {
  background-color: #fef5d4;
  border-color: #fef5d4;
  color: #fec801 !important;
}

.edutech-dropdown-admin {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(-128px, 21px);
}

.edutech-edit-btn {
  padding: 4px 10px 9px 10px;
}

.edutech-edit-btn:hover,
.edutech-edit-btn:active {
  color: var(--active-primary-color);
  border-radius: 50%;
  background: #d3e1f4;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.edutech-delete-btn:active,
.edutech-delete-btn:hover {
  color: #ff8181;
  border-radius: 50%;
  background: #f4e1e1;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.edutech-delete-btn {
  padding: 4px 10px 9px 10px;
}

.edutech-admin-icon-size {
  font-size: 25px;
}

.edutech-admin-three-dot-icon {
  padding: 2px 1px 4px 5px;
  border: none;
  background: none;
  color: var(--text-secondary);
}

.edutech-admin-dots-inner {
  width: 198px;
  display: flex;
  align-items: end;
}

.edutech .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

button#clinician-table-three-button-dropdown {
  background: white;
  border: none;
}

.edutech .edutech-admin-primary {
  background: #00386e;
  color: white;
  border-color: #00386e;
}

button.btn.btn-.edutech-admin-primary {
  background: #002242;
  border-color: #002242;
  color: white;
}

.edutech-admin-courses .dropdown-toggle::after {
  display: inline-block;
  float: top;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 10px;
}

.edutech-admin-courses .btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  background-color: #ffffff;
  border: 1px solid #001428;
  color: #001428;
}

button#dropdown-basic {
  background: white;
  color: black;
  border-color: 2px solid gainsboro !important;
}

/* preview curriculum */

.curriculum-preview-span {
  background: #cbcbd0;
  margin-left: 15px;
  padding: 3px 7px 3px 7px;
  border-radius: 15px;

  font-size: 12.642px;
  font-weight: 500;
}

.curriculum-button {
  background-color: var(--primary-color) !important;
  border: var(--Primary-color) !important;
  font-weight: 600 !important;
  outline: none !important;
  color: #fff;
  border-radius: 10px;
  padding: 3px 18px !important;
}

.curriculum-background {
  background: #00386e45;
  border-radius: 10px;
}

.curriculum-right {
  background-color: #00386e !important;
  border-color: #00386e !important;
}

.curriculum-title-container {
  background: #ffe4c480;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
}

.curriculum-icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.curriculum-img {
  width: 100%;
}

.curriculum-list {
  list-style-type: none;
}

.curriculum-card-height {
  height: 100vh;
}

.curriculum-progress-text {
  font-size: 20px;
}

.curriculum-progress-bar {
  height: 10px;
}

.curriculum-card {
  border: 1px solid lightgrey;
  background: #f7f7f7;
  border-radius: 0px !important;
}

.curriculum-card-span {
  background: #2b3636;
  color: #fff;
  display: block;
  height: 27px;
  line-height: 28px;
  border-radius: 3px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  float: left;
  padding: 0px 15px;
}

.curriculum-input-width {
  width: 50%;
  background: white !important;

  color: black;
  border: 1px solid lightgrey !important;
}

.curriculum-input-width:hover,
.curriculum-input-width:focus {
  color: black !important;
}

.curriculum-check-icon {
  width: 15rem;
  height: 9rem;
  color: green;
}

.curriculum-btn-width {
  /* width: 35%!important; */
  background: #00386e !important;
  border-color: #00386e !important;
}

.quiz-btn-select,
.quiz-btn-select:hover,
.quiz-btn-select:focus {
  background: #00386e !important;
  color: white !important;
}

.quiz-checkbox {
  text-align: center;
  top: 15px;
  right: 40px;
}

.quiz-delete {
  font-size: 25px;
  cursor: pointer;
}

.quiz .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.quiz-new-checkbox {
  left: 37px;
}

.fw-600 {
  font-weight: 600;
}

.VideoInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* border: 4px dashed #d7d5d5;
  height: 85px;
  /* border: 1px solid #ddd; */
}

.VideoInput_input {
  display: none;
}

.VideoInput_video {
  display: block;
  margin: 0;
  border: 1px dotted;
}

.VideoInput_footer {
  background: #eee;
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
}

.video-btn-style {
  width: 100%;
  height: 95px;
  border: 4px dotted;
  border-radius: 15px;
}

.description-rounded {
  border-radius: 15px;
}

.edutech-faqs-heading {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;

  font-size: 43px;
}

.edutech-content {
  width: 90%;
  padding: 0 !important;
  margin: 0 auto;
  padding: 0 60px 0 0;
}

.edutech-centerplease {
  margin: 0 auto;
  max-width: 270px;
  font-size: 40px;
}

.faq_section {
  margin-bottom: 1rem;
  border: 1px solid #00386e2e;
  border-radius: 10px;
  background: #fff;
}

.edutech-question {
  color: #000;
  position: relative;
  background: #fff;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
}

.edutech-question.active,
.edutech-plus.active {
  color: var(--secondary-color);
}

.edutech-answers {
  font-weight: 500;
  font-size: 15px;
  border-radius: 10px;
  background: #fff;
  padding: 0px 15px;
  margin: 0px 0;
  height: 0;
  overflow: hidden;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.7s ease;
  -moz-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
}

.edutech-answers p {
  margin: 0;
}

.edutech-questions:checked ~ .edutech-answers {
  height: auto;
  opacity: 1;
  padding: 22px;
}

.edutech-plus {
  color: #000;
  position: absolute;
  margin-left: 13px;
  margin-top: 8px;
  z-index: 5;
  font-size: 2em;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.login_support_section {
  border: 0;
  margin: 10rem 0;
  border-radius: 15px;
  background: #fff;
}

.height_set {
  height: 100vh;
}

.logo-img-set {
  text-align: center;
  position: relative;
  top: -7rem;
}

.logo-img-set img {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  object-fit: scale-down;
  background: #fff;
}
.main-section-margin .logo-img-set img {
  width: 13rem;
  height: 13rem;
  border-radius: 50%;
  object-fit: scale-down;
  background: #fff;
  padding: 2rem;
}
.view-style {
  text-decoration: underline;
  color: var(--primary-color);
  cursor: pointer;
}
.edutech-questions:checked ~ .edutech-plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.edutech-questions {
  display: none;
}

.edutech-query-text {
  color: #00386e !important;
  background: none !important;
  border: none !important;
  text-decoration: underline;
}

.edutech-query-text:hover {
  text-decoration: underline;
}
.video-size-class {
  font-size: 11px;
}
.faq-edit-icon-style {
  float: right;
  margin-top: -36px;
  z-index: 2;
  position: relative;
  margin-right: 13px;
}
.contact-us-page-span-style {
  background: #e5d1d1;
  border-radius: 15px;
  padding: 5px 10px 3px 10px;
}
.student-name-class {
  font-size: 20px;
  font-weight: 700;
}
.student-message-class {
  font-size: 16px;
  font-weight: 400;
  color: #a79d9d;
  width: 90%;
}
.react-bs-table-no-data {
  text-align: center !important;
  padding: 25px !important;
}
.table-span-contactus {
  padding: 4px 8px;
  font-size: 0.702rem;
  line-height: 1.053rem;
  text-transform: uppercase;
  background-color: #d7f3eb;
  color: #000;
  border-radius: 4px;
}
.edutech-faqs-name-text {
  padding: 10px !important;
  border-radius: 15px;
  background: #f7f7f7;
}
.teacher-dashboard button#dropdown-basic {
  background: #f7f7f7 !important;
  color: black;
}
.contact-us-margin {
  margin-top: -5rem !important;
}
.main-section-margin {
  margin-top: 7rem !important;
}
/* Media Query */

@media screen and (max-width: 768px) {
  .edutech-sm-margin {
    margin: 0px !important;
  }

  .edutech-card-padding {
    padding-top: 0px !important;
  }

  .edutech-card2-padding {
    padding-top: 25px !important;
  }

  .edutech-sm-button-padding {
    margin-top: 40px !important;
  }

  .edutech-card-course-text {
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-color);
    text-align: center;
  }

  .edu-card-lg-text {
    font-size: 11px;
  }

  .table-adjust .table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .edutech-sm-mt-5 {
    margin-top: 15px !important;
  }

  .edutech-certificate {
    margin-top: 0px !important;
  }

  .edutech-next-course-text {
    padding-top: 15px !important;
  }

  .edutech-next-course-card {
    padding-top: 10px !important;
  }

  .edutech-video-span-determine-text {
    background: var(--secondary-color);
    padding: 10px;
    color: white;
    margin-right: 35px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .edutech-video-span-profit-text {
    background: var(--secondary-color);
    padding: 10px 0px 10px 30px;
    color: white;
    font-size: 14px;
    font-weight: 500;
  }

  .edutech-video-span {
    position: absolute;
    top: 20%;
  }

  .edutech-curriculum-cancel-button {
    background: #e5e5e5 !important;
    border: #e5e5e5 !important;
    color: black;
    margin-top: 20px;
    padding: 15px 4rem 15px 4rem;
    margin-right: 10px;
  }

  .edutech-curriculum-action-button {
    /* margin-top: 20px; */
    padding: 15px 4rem 15px 4rem;
    background-color: var(--Primary-color) !important;
    /* border: var(--primary-color)!important; */
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .f-20 {
    font-size: 20px;
  }

  .f-18 {
    font-size: 18px;
  }

  .f-28 {
    font-size: 28px;
  }
}

@media (min-width: 1440px) {
  .f-20 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .edutech-curriculum-cancel-button {
    background: #e5e5e5 !important;
    border: #e5e5e5 !important;
    color: black;
    margin-top: 20px;
    padding: 15px 2rem 15px 2rem;
    margin-right: 10px;
  }

  .edutech-curriculum-action-button {
    /* margin-top: 20px; */
    padding: 15px 2rem 15px 2rem;
    background-color: var(--primary-color) !important;
    /* border: var(--primary-color)!important; */
  }
}

#chat-box-body button#dropdown-basic {
  border: 1px solid var(--primary-color);
  border-radius: 0.25rem;
}

/* Chat module */

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 25px 10px;
  height: calc(100% - 50px);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  color: #ffffff;
  border-bottom-right-radius: 0;
  background: #579ffb;
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
}

.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eeeeee6b;
}

.msger-input {
  flex: 1;
  background: #ddd;
}

.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  margin: 10px;
  border-radius: 15px;
  background: #dddddd38;
}

.height_adjust_chat {
  height: 385px;
  overflow: auto;
}

/* .chat-w-50{
  width: 50%;
  text-align: center;
} */
#chat-box-body .explore_tabs {
  justify-content: unset !important;
}

#chat-box-body .explore_tabs .nav-item {
  width: 50%;
  text-align: center;
}

#chat-box-body .tab-content {
  padding-top: 0 !important;
}

.login_navbar_set .navbar-brand img {
  display: block !important;
  width: 10rem;
  margin-left: 1.5rem;
}

.loginscreen-explore {
  width: 89%;
}

.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show {
  width: 25em;
  border-radius: 15px;
}

.swal2-icon {
  margin: 1.5em auto 0.6em;
}

.swal2-popup.swal2-modal.swal2-show {
  width: 25em;
  border-radius: 15px;
}

.edutech-course-img-size {
  width: 4em;
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  object-fit: cover;
  height: 62px;
}

.video-fluid {
  width: 25%;
  height: auto;
}

.thumbup:hover {
  color: #175b9d;
}

.thumbdown:hover {
  color: #175b9d;
}

.edutech-payment-inout-field {
  color: #424242;
  background-color: #f7f8f9;
  border: none !important;
  border-radius: 10px;
  padding: 0.255rem 2.5rem 0.255rem 1rem;
  height: 48px;
  font-weight: 700;
}

.edutech-payment-inout-field:focus {
  box-shadow: none;
  border: none;
  background-color: #f7f8f9;
}

.payment-primary {
  background-color: #00386e !important;
  border-color: #00386e !important;
  border: 0;
  border-radius: 10px;
  padding: 12px 6px;
  font-size: 16px;
  color: #fff;
  width: 30%;
  font-weight: 500;
}

.edutech-admin-courses .btn-success {
  border: none;
  color: #333;
  border-radius: 12px;
  background-color: #f7f7f7 !important;
  font-size: 15px;
  min-height: unset;
  padding: 15px 15px;
}

.edutech-admin-courses .btn-success:hover {
  border: none !important;
}

.edutech-admin-courses .btn-success:active {
  border: none !important;
}

.edutech-admin-courses .btn-success:focus {
  border: none !important;
}

.Uploadfield {
  border: 4px dashed black;
}

.Uploadinput {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}

.Uploadtext {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 170px;
  color: black;
  font-family: Arial;
}

.inputDnD .form-control-file {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 6em;
  outline: none;
  visibility: hidden;
  cursor: pointer;
  background-color: black;
  box-shadow: 0 0 5px solid currentColor;
}

.inputDnD .form-control-file:before {
  content: attr(data-title);
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 6em;
  line-height: 2em;
  padding-top: 1.5em;
  opacity: 1;
  visibility: visible;
  text-align: center;
  border: 0.25em dashed currentColor;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: visible;
}

.inputDnD .form-control-file:hover:before {
  border-style: solid;
  box-shadow: inset 0px 0px 0px 0.25em currentColor;
}

.Edutech-cert.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
  color: white;
  overflow-x: scroll;
  font-family: "Montserrat";
  background-color: #c5d7e7;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.Edutech-cert p {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

.Edutech-cert .upload-button {
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Edutech-cert .upload-file {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.Edutech-cert .button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  border: 0;
}

.upload-cert .Edutech-cert.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 402px;
  color: white;
  overflow-x: scroll;
  font-family: "Montserrat";
  background-color: #c5d7e7;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.certificate-btn .upload_thumbnail_btn {
  padding: 7px 22px;
  background-color: var(--secondary-color) !important;
  border: var(--primary-color) !important;
  color: #fff;
  border-radius: 4px;
  width: 200px !important;
  cursor: pointer;
}

.certificate-btn .upload_thumbnail_input {
  opacity: 0;
  position: absolute;
  left: 15px;
  top: 40px !important;
  width: inherit;
  cursor: pointer;
}

.resourse .upload_thumbnail_input {
  opacity: 0;
  position: absolute;
  left: 15px;
  top: 40px !important;
  width: inherit;
  cursor: pointer;
}

.quiz-edutech-curriculum-action-button {
  padding: 10px 1rem 10px 1rem !important;
  background-color: var(--primary-color) !important;
  border: var(--primary-color) !important;
}

.pricing-plan-height-limit {
  min-height: 185px;
  height: 185px;
  overflow: auto;
}

.pricing-plan-other-selection,
.pricing-plan-other-selection:hover,
.pricing-plan-other-selection:active {
  background-color: #f9f9f9 !important;
  border-radius: 15px;
  padding: 12px 6px;
  border: 0px !important;
  font-size: 16px;
  color: #000 !important;
  width: 100%;
  font-weight: 500;
}
.longtext-dotted-format {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  cursor: pointer;
}
.link-color {
  color: #0606e791 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #f7f8f9e3 inset !important;
}
.VideoInput_input_after_upload {
  width: 98px;
}
.active-wrong {
  background-color: #e61515 !important ;
  color: white;
}
.active-success {
  background-color: #28a745 !important ;
  color: white;
}

.stepper-active {
  height: 25px;
  width: 25px;
  background-color: dimgrey;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Mui-completed .stepper-active {
  background-color: rgb(0, 49, 112) !important;
  color: white;
}
.prev-arrow-position {
  left: 0% !important;
}
.course-description-text-scroll {
  overflow-y: auto;
  max-height: 29px;
}
.card-bode-height-set {
  overflow-y: auto;
  max-height: 400px;
  min-height: 60px;
}
.card-height-personal-reco {
  max-height: 300px;
  height: 184px;
}
.IsReadCountAlignment {
  position: absolute;
  top: 27px;
  border-radius: 54%;
  background: #00386e;
  font-size: 12px;
  padding: 2px 6px;
  color: white;
  right: 5px;
}
.longtextdottedone {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Myprogress-course-icons {
  position: absolute;
  border-radius: 50%;
  height: 40px;
  width: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--primary-color);
  top: 48% !important;
  z-index: 1;
  font-size: 43px !important;
  cursor: pointer;
  color: white;
}
.name-styling-field {
  margin-bottom: 5rem;
}
.background-image-styling {
  background-image: url(/src/assets/images/bg.jpg);
  background-position: center;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  /* overflow: auto; */
  background-position-x: right;
}
.padding-styling {
  padding: 7rem;
}
.industry-type-parent-class.dropdown_bg_grey .dropdown-menu {
  overflow-x: auto !important;
  height: 235px !important;
}
.feedback-icon-styling {
  position: absolute;
  right: 0px;
  top: -10px;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 3px 2px 3px;
  color: var(--primary-color);
  cursor: pointer;
}
.searchfilter-text-style {
  text-align: center;
  margin-top: 4rem;
}
.dropdown_bg_grey .dropdown-menu.show {
  min-height: 200px !important;
  height: 244px !important;
  overflow-x: auto !important;
}
.dropdown_bg_grey .dropdown-menu.provider_class.show {
  min-height: 150px !important;
  height: 150px !important;
  overflow-x: auto !important;
}
.parent-role-permission.dropdown_bg_grey .dropdown-menu.show {
  min-height: 150px !important;
  height: 150px !important;
  overflow-x: auto !important;
}
.zoom-link-class {
  color: #7f839a !important;
}
.password-shown-parent .show_pswd {
  position: absolute;
  right: 20px;
  margin-top: -33px;
  margin-right: 10px;
  color: #00386e;
  cursor: pointer;
  font-size: 20px;
}
.role-parent button#dropdown-basic {
  background: #e1e1e1 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #00386e !important;
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: #00386e1c !important;
}
.background-image-certificate {
  background-image: url(./../images/bg.png);
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: max-content; */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: max-content;
}
.cert-text-size {
  font-size: 73pt;
}
.name-style {
  font-size: 38pt;
}
.appreciate-text {
  font-size: 26pt;
  margin-left: 6px;
}
.signature-style {
  position: absolute;
  margin-top: -5rem;
  margin-left: 30px;
}
.notes-download-btn {
  padding: 10px 25px;
  height: 45px;
  background: var(--primary-color);
  border-radius: 6px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
}
.download-btn-style {
  margin-right: 5px;
  margin-bottom: 3px;
}
.privacy-policy-styling-parent .edutech-logo {
  text-align: center;
  border-bottom: 0px solid #fff;
  padding: 0.7rem 0;
}
.image-size-style {
  margin-top: -30px;
  margin-left: 6rem;
}
.img-fluid.candletech-logo {
  max-width: 55% !important;
  margin-top: 35px;
  margin-left: 80px;
}
.authorize-text {
  font-size: 14pt;
}
.Principal-text {
  font-size: 13pt;
}
